<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay"
    :lessonNum="11"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'yellowColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          english: "b",
          image: require(`@/assets/img/14-Pinyin/chapter-02/pinyin-b-lips.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          english: "p",
          image: require(`@/assets/img/14-Pinyin/chapter-02/pinyin-p-lips.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          english: "m",
          image: require(`@/assets/img/14-Pinyin/chapter-02/pinyin-m-lips.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "two",
          isShowImg: "true",
          currentNum: 4,
          english: "f",
          image: require(`@/assets/img/14-Pinyin/chapter-02/pinyin-f-lips.svg`),
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
